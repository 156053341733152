<template>
    <Dialog :header="'Nova Função para ' + formLocal.name" :modal="true" :closable="false" :style="{ width: '45vw' }">
        <div class="formgrid grid">
            <div class="field col-12">
                <label for="change_date">Data Alteração</label>
                <Calendar
                    required="true"
                    class="w-12"
                    id="change_date"
                    dateFormat="dd/mm/yy"
                    v-model="formLocal.changeDate"
                    :show-icon="true"
                    placeholder="Informe uma data..."
                    :class="{ 'p-invalid': submitted && !formLocal.changeDate }"
                />
                <small class="p-error" v-if="submitted && !formLocal.changeDate">Campo obrigatório.</small>
            </div>
        </div>

        <div class="field">
            <label for="customerBranchesId">Unidade</label>
            <DropdownUnidade
                class="w-full"
                id="customerBranchesId"
                v-model="formLocal.customerHierarchy.customerBranch"
                @change="onChangeFindHierarchy($event)"
                placeholder="Escolha uma unidade"
                :customerId="form.customerId"
                :class="{ 'p-invalid': submitted && !formLocal.customerHierarchy.customerBranch }"
            />
            <small class="p-error" v-if="submitted && !formLocal.customerHierarchy.customerBranch">Campo obrigatório.</small>
        </div>

        <div v-if="temPermissao()" class="formgrid grid">
            <div class="field col">
                <label for="customer_department">Setor</label>
                <DropdownSetor
                    class="w-full"
                    id="customer_department"
                    v-model="formLocal.customerHierarchy.customerDepartment"
                    @change="onChangeFindHierarchy($event)"
                    placeholder="Escolha um setor"
                    :customerId="form.customerId"
                    :class="{ 'p-invalid': submitted && !formLocal.customerHierarchy.customerDepartment }"
                />
                <small class="p-error" v-if="submitted && !formLocal.customerHierarchy.customerDepartment">Campo obrigatório.</small>
            </div>
            <div class="field col">
                <label for="customer_department">Cargo</label>
                <DropdownCargo
                    class="w-12"
                    id="customer_position"
                    v-model="formLocal.customerHierarchy.customerPosition"
                    @change="onChangeFindHierarchy($event)"
                    placeholder="Escolha um cargo"
                    :customerId="form.customerId"
                    :class="{ 'p-invalid': submitted && !formLocal.customerHierarchy.customerPosition }"
                />
                <small class="p-error" v-if="submitted && !formLocal.customerHierarchy.customerPosition">Campo obrigatório.</small>
            </div>
        </div>
        <div v-if="!temPermissao()" class="formgrid grid">
            <div class="field col">
                <label for="customer_department">Setor</label>
                <DropdownSetorByHierarquia
                    id="customerDepartments"
                    v-on:before-hide="onChangeFindHierarchy($event)"
                    :loading="loadingHierarquia"
                    class="w-full"
                    v-model="formLocal.customerHierarchy.customerDepartment"
                    :customerId="form.customerId"
                    :branchId="formLocal.customerHierarchy.customerBranchId"
                    :class="{ 'p-invalid': submitted && !formLocal.customerHierarchy.customerDepartmentId }"
                />
                <small class="p-error" v-if="submitted && !formLocal.customerHierarchy.customerDepartmentId">Campo obrigatório.</small>
            </div>
            <div class="field col">
                <label for="customer_department">Cargo</label>
                <DropdownCargoByHierarquia
                    id="customerPositionId"
                    v-on:before-hide="onChangeFindHierarchy($event)"
                    :loading="loadingHierarquia"
                    class="w-full"
                    v-model="formLocal.customerHierarchy.customerPosition"
                    :customerId="form.customerId"
                    :branchId="formLocal.customerHierarchy.customerBranchId"
                    :departmentId="formLocal.customerHierarchy.customerDepartmentId"
                    :class="{ 'p-invalid': submitted && !formLocal.customerHierarchy.customerPositionId }"
                />
                <small class="p-error" v-if="submitted && !formLocal.customerHierarchy.customerPositionId">Campo obrigatório.</small>
            </div>
        </div>
        <div v-show="naoTemHierarquia && enviaEmailFuncionarioSemHierarquia" class="formgrid grid">
            <div class="field col">
                <label for="descricaoRisco">Descrição do risco</label>
                <Textarea
                    type="text"
                    id="descricaoRisco"
                    class="w-full"
                    v-model="formLocal.descricaoRisco"
                    :class="{ 'p-invalid': submitted && !form.descricaoRisco && naoTemHierarquia && enviaEmailFuncionarioSemHierarquia }"
                />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="fecharNewFunction" />
            <Button label="Salvar" icon="pi pi-check" :loading="loading" autofocus @click="saveNewFunction" />
        </template>

        <Toast @click="$router.push('/hierarchy/new')" style="cursor: pointer" group="funcao">
            <template #message="slotProps">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                <div class="flex ml-4 p-0">
                    <div class="text-center">
                        <h6>
                            {{ slotProps.message.summary }}<br />
                            <b>{{ slotProps.message.detail }}</b>
                        </h6>
                    </div>
                </div>
            </template>
        </Toast>

        <Toast @click="$router.push('/gestaoMedica/request')" style="cursor: pointer" group="solicitacao">
            <template #message="slotProps">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                <div class="flex ml-4 p-0">
                    <div class="text-left">
                        <h6>
                            {{ slotProps.message.summary }}
                            <b>{{ slotProps.message.detail }}</b>
                        </h6>
                    </div>
                </div>
            </template>
        </Toast>
        <MudancaRiscosDialog
            v-bind:form="form"
            v-if="showMudancaRiscos"
            :possuiMudancaRiscos="possuiMudancaRiscos"
            :riscos="mudancaDeRiscos"
            v-model:visible="showMudancaRiscos"
            v-bind:fecharMudancaRiscos="fecharMudancaRiscos"
        />
    </Dialog>
</template>

<script>
import BaseService from '@/services/BaseService';
import MudancaRiscosDialog from './MudancaRiscosDialog.vue';
import DropdownUnidade from '../../unidades/components/DropdownUnidade.vue';
import DropdownSetor from '../../setores/components/DropdownSetor.vue';
import DropdownCargo from '../../cargos/components/DropdownCargo.vue';
import DropdownSetorByHierarquia from '../components/DropDownsPorHierarquia/DropdownSetorByHierarquia.vue';
import DropdownCargoByHierarquia from '../components/DropDownsPorHierarquia/DropdownCargoByHierarquia.vue';

export default {
    props: ['form', 'fecharNewFunction', 'load'],
    model: {
        prop: 'form',
        event: 'formchange'
    },
    components: { MudancaRiscosDialog, DropdownUnidade, DropdownSetor, DropdownCargo, DropdownCargoByHierarquia, DropdownSetorByHierarquia },
    data() {
        return {
            enviaEmailFuncionarioSemHierarquia: false,
            naoTemHierarquia: false,
            loadingHierarquia: false,
            submitted: false,
            showMudancaRiscos: false,
            possuiMudancaRiscos: true,
            loading: false
        };
    },
    computed: {
        formLocal: {
            get: function () {
                return this.form || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        }
    },
    watch: {
        'formLocal.customerHierarchy'() {
            this.enviaEmailFuncionarioSemHierarquia = this.formLocal?.customer?.enviaEmailFuncionarioSemHierarquia;
        },
        'formLocal.customerHierarchy.customerBranch'() {
            this.formLocal.customerHierarchy.customerBranchId = this.formLocal.customerHierarchy?.customerBranch?.id;
            this.formLocal.customerHierarchy.customerPosition = null;
            this.formLocal.customerHierarchy.customerDepartment = null;
        },
        'formLocal.customerHierarchy.customerDepartment'() {
            this.formLocal.customerHierarchy.customerDepartmentId = this.formLocal.customerHierarchy?.customerDepartment?.id;
            this.formLocal.customerHierarchy.customerPosition = null;
        },
        'formLocal.customerHierarchy.customerPosition'() {
            this.formLocal.customerHierarchy.customerPositionId = this.formLocal.customerHierarchy?.customerPosition?.id;
        }
    },
    async mounted() {
        this.$serviceNovaFuncao = new BaseService('/customer/employees/mudanca-de-funcao');
        this.$serviceHierarchy = new BaseService('/customer/hierarchies/has-hierarchy');

        this.enviaEmailFuncionarioSemHierarquia = this.formLocal?.customer?.enviaEmailFuncionarioSemHierarquia;
    },
    methods: {
        temPermissao() {
            return this.$checkPermission('estrutura_funcionario:exibir_sem_filtro_de_hierarquia');
        },

        async saveNewFunction() {
            this.submitted = true;
            this.$toast.removeAllGroups();
            if (
                !this.formLocal.changeDate ||
                !this.formLocal.customerId ||
                !this.formLocal.customerHierarchy.customerBranch ||
                !this.formLocal.customerHierarchy.customerDepartment ||
                !this.formLocal.customerHierarchy.customerPosition
            )
                return;
            try {
                this.loading = true;
                this.formLocal.customerHierarchy.customerId = this.formLocal.customerId;
                this.mudancaDeRiscos = await this.$serviceNovaFuncao.save({
                    idOrigem: this.formLocal.id,
                    data: this.formLocal.changeDate,
                    idUnidade: this.formLocal.customerHierarchy.customerBranch.id,
                    idSetor: this.formLocal.customerHierarchy.customerDepartment.id,
                    idCargo: this.formLocal.customerHierarchy.customerPosition.id,
                    idFuncionario: this.formLocal.id,
                    descricaoRisco: this.formLocal.descricaoRisco || ''
                });
                if (this.mudancaDeRiscos.data.riscosAntigos?.length || this.mudancaDeRiscos.data.riscosNovos?.length) {
                    this.possuiMudancaRiscos = true;
                    this.showMudancaRiscos = true;
                    return;
                }
                this.possuiMudancaRiscos = false;
                this.showMudancaRiscos = true;
            } catch (error) {
                this.loading = false;
                const message = error?.response?.data?.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao alterar função: ' + message, life: 6000 });
            }
        },
        fecharMudancaRiscos() {
            this.novaFuncao = false;
            this.submitted = false;
            this.loading = false;
            this.showMudancaRiscos = false;
            this.fecharNewFunction();
            this.load();
        },
        async onChangeFindHierarchy() {
            this.$toast.removeAllGroups();
            this.loadingHierarquia = true;

            if (
                this.formLocal.customerId &&
                this.formLocal.customerHierarchy.customerBranch &&
                this.formLocal.customerHierarchy.customerDepartment &&
                this.formLocal.customerHierarchy.customerPosition
            ) {
                const { data } = await this.$serviceHierarchy.findAll({
                    customerId: this.formLocal.customerId,
                    customerBranchId: this.formLocal.customerHierarchy.customerBranch.id,
                    customerDepartmentId: this.formLocal.customerHierarchy.customerDepartment.id,
                    customerPositionId: this.formLocal.customerHierarchy.customerPosition.id
                });

                this.naoTemHierarquia = !data?.id;
                this.formLocal.customerHierarchy.id = data?.id;
                if (this.naoTemHierarquia) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Hierarquia não encontrada',
                        life: 10000
                    });
                }
            }
            this.loadingHierarquia = false;
        }
    }
};
</script>
